<div
    class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark main-container"
  >
    <a
      href="/"
      class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
    >
      <!-- <svg class="bi me-2" width="40" height="32">
        <use xlink:href="#bootstrap" />
      </svg> -->
      <img src="assets/fairylogo.png" width="40" height="32">
      <span class="fs-4 nonito">Ey Fairies</span>
    </a>
    <hr />
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <a href="#" class="nav-link clr-wht nonito" aria-current="page" routerLink="/admin-rescue" routerLinkActive="active">
          <!-- <svg class="bi me-2" width="16" height="16">
            <use xlink:href="#home" />
          </svg> -->
          Admin Rescue
        </a>
        <a href="#" class="nav-link clr-wht nonito" aria-current="page" routerLink="/admin-aftercare" routerLinkActive="active">
          <!-- <svg class="bi me-2" width="16" height="16">
            <use xlink:href="#home" />
          </svg> -->
          Admin After Care
        </a>
      </li>
      <!-- <li>
        <a href="#" class="nav-link text-white">
          <svg class="bi me-2" width="16" height="16">
            <use xlink:href="#speedometer2" />
          </svg>
          Dashboard
        </a>
      </li>
      <li>
        <a href="#" class="nav-link text-white">
          <svg class="bi me-2" width="16" height="16">
            <use xlink:href="#table" />
          </svg>
          Orders
        </a>
      </li>
      <li>
        <a href="#" class="nav-link text-white">
          <svg class="bi me-2" width="16" height="16">
            <use xlink:href="#grid" />
          </svg>
          Products
        </a>
      </li>
      <li>
        <a href="#" class="nav-link text-white">
          <svg class="bi me-2" width="16" height="16">
            <use xlink:href="#people-circle" />
          </svg>
          Customers
        </a>
      </li>-->
    </ul> 
    <hr />
    <div class="dropdown">
      <a
        href="#"
        class="d-flex align-items-center text-white text-decoration-none dropdown-toggle nonito"
        id="dropdownUser1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          src="assets/fairylogo.png"
          alt=""
          width="32"
          height="32"
          class="rounded-circle me-2"
        />
        <strong>Ey Fairies Admin</strong>
      </a>
      <ul
        class="dropdown-menu dropdown-menu-dark text-small shadow nonito"
        aria-labelledby="dropdownUser1"
      >
        <li><a class="dropdown-item pointer" (click)="logout()">Sign out</a></li>
      </ul>
    </div>
  </div>

  