<div class="container-fluid d-flex justify-content-center">
  <img src="assets/aboutlogo.png" width="600" />
</div>
<div class="container-fluid pb-5 row justify-content-center source-sans">
  <div class="col-md-8">
    <h1 class="text-center  nonito"><b>PALABOY: an Animated Shortfilm</b></h1>
    <p class="text-justify">
      Palaboy is an animated short film about a stray dog and its journey to
      survive daily life by scavenging for food and looking for shelter until it
      was adopted by an empathetic crew of a fast-food restaurant. The main
      objective of this film is to create an educational animated tale that will
      raise awareness about the current phenomenon of homeless animals and how
      to help them in a variety of methods.
    </p>
  </div>
  <div class="container-fluid d-flex justify-content-center">
    <img src="assets/fairy.png" />
  </div>
  <div class="col-md-8">
    <h1 class="text-center py-4  nonito"><b>Meet the team behind PALABOY!</b></h1>
  </div>

  <div class="row text-center">

    <div class="col-xl-3 col-sm-6 mb-5">
      <div class="bg-white rounded shadow-sm py-5 px-4"><img src="assets/elaine.png" alt="" width="150" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm">
        <h5 class="mb-0">Elaine Rose Urbano</h5><span class="small text-uppercase text-muted">UI/UX Designer</span>
      </div>
    </div>
  
    <div class="col-xl-3 col-sm-6 mb-5">
      <div class="bg-white rounded shadow-sm py-5 px-4"><img src="assets/romyr.png" alt="" width="150" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm">
        <h5 class="mb-0">Romyr Guingoma</h5><span class="small text-uppercase text-muted">Writer/Technical Developer</span>
      </div>
    </div>
  
    <div class="col-xl-3 col-sm-6 mb-5">
      <div class="bg-white rounded shadow-sm py-5 px-4"><img src="assets/stacy.png" alt="" width="150" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm">
        <h5 class="mb-0">Stacy Mae Cuenca</h5><span class="small text-uppercase text-muted">Project Manager</span>
      </div>
    </div>
  
    <div class="col-xl-3 col-sm-6 mb-5">
      <div class="bg-white rounded shadow-sm py-5 px-4"><img src="assets/kristine.png" alt="" width="150" class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm">
        <h5 class="mb-0">Kristine Angela Biag</h5><span class="small text-uppercase text-muted">Software Developer</span>
      </div>
    </div>
  
  </div>
</div>


<app-footer></app-footer>