<div class="container-fluid p-5 row justify-content-center source-sans" *ngFor="let item of after_care">
  <div class="col-md-8">
    <h1 class="text-center  nonito">
      <b>{{item.title[0]}}</b>
    </h1>
    <p class="text-center"><b>{{item.title[1]}}</b></p>
  </div>
  <div class="container-fluid d-flex justify-content-center p-3 row">
    <div class="col-md-4 pb-3">
      <div class="container-fluid rounded-3 shadow bg-light p-5">
        <h4 class="text-center pb-3  nonito"><b>{{item.box_1[0]}}</b></h4>
        <p class="p-0 m-0 text-justify">
          {{item.box_1[1]}}
        </p>
      </div>
    </div>
    <div class="col-md-4 pb-3">
      <div class="container-fluid rounded-3 shadow bg-light p-5">
        <h4 class="text-center pb-3  nonito"><b>{{item.box_2[0]}}</b></h4>
        <p class="p-0 m-0 text-justify">
          {{item.box_2[1]}}
        </p>
      </div>
    </div>
    <div class="col-md-4 pb-3">
      <div class="container-fluid rounded-3 shadow bg-light p-5">
        <h4 class="text-center pb-3  nonito"><b>{{item.box_3[0]}}</b></h4>
        <p class="p-0 m-0 text-justify">
          {{item.box_3[1]}}
        </p>
      </div>
    </div>
    <div class="col-md-4 pb-3">
      <div class="container-fluid rounded-3 shadow bg-light p-5">
        <h4 class="text-center pb-3 nonito"><b>{{item.box_4[0]}}</b></h4>
        <p class="p-0 m-0 text-justify">
          {{item.box_4[1]}}
        </p>
      </div>
    </div>
    <div class="col-md-4 pb-3">
      <div class="container-fluid rounded-3 shadow bg-light p-5">
        <h4 class="text-center pb-3 nonito"><b>{{item.box_5[0]}}</b></h4>
        <p class="p-0 m-0 text-justify">
          {{item.box_5[1]}}
        </p>
      </div>
    </div>
    <div class="col-md-4 pb-3">
      <div class="container-fluid rounded-3 shadow bg-light p-5">
        <h4 class="text-center pb-3 nonito"><b>{{item.box_6[0]}}</b></h4>
        <p class="p-0 m-0 text-justify">
          {{item.box_6[1]}}
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <section id="dangerousfood">
      <p class="text-big text-center  nonito">
        <b>{{item.title[2]}}</b>
      </p>
      <div class="container-fluid d-flex justify-content-center">
        <img src="assets/badfood.png" />
      </div>
    </section>
    <section
      id="safefood"
      class="row d-flex justify-content-center"
    >
      <div class="col-md-8">
        <p class="text-big text-center  nonito">
          <b>{{item.title[3]}}</b>
        </p>
        <p class="fs-3 "><b>{{item.discussions[0]}}</b></p>
        <p class="text-justify">
          {{item.discussions[1]}}
        </p>
        <p class="text-justify">
          {{item.discussions[2]}}
        </p>
        <p class="fs-3">
          <b>{{item.discussions[3]}}</b>
        </p>
        <p class="text-justify">
          {{item.discussions[4]}}
        </p>
        <p class="text-justify">
          {{item.discussions[5]}}
        </p>
        <p class="fs-3">
          <b>{{item.discussions[6]}}</b>
        </p>
        <p class="text-justify">
          {{item.discussions[7]}}
        </p>
        <p class="text-justify">
          {{item.discussions[8]}}
        </p>
        <p class="text-justify">
          {{item.discussions[9]}}
        </p>
      </div>
    </section>
    <section id="bathe">
      <div class="container-fluid justify-content-center d-flex">
        <img src="assets/bathe.png" class="w-75" />
      </div>
    </section>
  </div>
</div>
<app-footer></app-footer>
