<body>
        <div class="container">
          <div class="row">
            <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body p-4 p-sm-5">
                  <h5 class="card-title text-center mb-5 fw-light fs-5 nonito">Ey Fairies Admin</h5>
                  <form (ngSubmit)="login()">
                    <div class="form-floating mb-3">
                      <input type="email" class="form-control" id="username" name="username" placeholder="name@example.com" [(ngModel)]="username" required >
                      <label for="username">Email address</label>
                    </div>
                    <div class="form-floating mb-3">
                      <input type="password" class="form-control" id="password" name="password" placeholder="Password" [(ngModel)]="password" required >
                      <label for="password">Password</label>
                    </div>
      
                    <div class="d-grid">
                      <button class="btn btn-primary btn-login text-uppercase fw-bold nonito" type="submit">Sign in</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>