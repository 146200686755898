<div class="container mt">
  <div class="row align-items-center">
    <div class="col">
      <h1 class="nonito"><b>PALABOY: An Animated Shortfilm</b></h1>
      <p class=""><b>by EY Fairies</b></p>
      <p class="">
        Palaboy is an animated short film about a stray dog and its journey to
        survive daily life by scavenging for food and looking for shelter until
        it was adopted by an empathetic crew of a fast-food restaurant. The main
        objective of this film is to create an educational animated tale that
        will raise awareness about the current phenomenon of homeless animals
        and how to help them in a variety of methods.
      </p>
    </div>
    <div class="col test">
        <iframe width="1000" height="600" src="https://www.youtube.com/embed/9976VfRiU8c?si=EVQ3k6Ilk8Z7qEmY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </div>
</div>

<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>

<app-footer></app-footer>
