<footer >
        <div class="credits d-flex flex-column flex-sm-row py-2 border-top">
          <div class="container-fluid row justify-content-start d-flex align-items-center">
              <ul class="nav d-flex p-0">
                  <li class="nav-item px-1"><a href="#" class="nav-link p-0 text-muted">Copyright and Disclaimer</a></li>
                  <li class="nav-item px-1"><a href="#" class="nav-link p-0 text-muted">Terms of Use</a></li>
                  <li class="nav-item px-1"><a href="#" class="nav-link p-0 text-muted">Privacy Policy</a></li>
                </ul>
          </div>
          <div class="container-fluid justify-content-end d-flex align-center align-items-center row">
              <div class="col-md-12 justify-content-end d-flex">© 2023   All Rights Reserved. PALABOY | EY Fairies</div>
            </div>
          </div>
   </footer>